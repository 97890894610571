// import "@babel/polyfill";
import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import pinia from "@/store";
import Antd from 'ant-design-vue';

const vue3PreviewImage = require('vue3-preview-image');

import 'ant-design-vue/dist/antd.less';
import '@/assets/less/common.less'

createApp(App).use(Antd).use(pinia).use(vue3PreviewImage).use(router).mount('#app')
